.Home_titleBar__3XDTs {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #0c1e35;
  color: rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 14px 0px rgb(0 0 0 / 75%);

  /* display: block; */
}

.Home_title__1dfd5 {
  font-size: 16px;
  font-weight: 600;
  /* margin-left: 32px; */
  margin-right: auto;
  /* margin-right: 32px; */
  color: rgba(255, 255, 255, 1);
  flex-shrink: 0;

  margin-left: auto;
  height: 30px;
  display: flex;
  align-items: flex-end;
}

.Home_title__1dfd5:hover {
  color: rgba(255, 255, 255, 1);
}

.Home_titlePrefix__vIot9 {
  font-weight: 400;
}

.Home_link__3O3aj,
.Home_link__3O3aj:visited {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  margin: 0 14px;
}

.Home_link__3O3aj:hover {
  box-shadow: 0 1px #0062ff;
}

.Home_link__3O3aj:active {
  color: rgba(255, 255, 255, 1);
}

.Home_link__3O3aj:focus {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px rgba(255, 255, 255, 1);
}

.Home_link__3O3aj.activeLink {
  box-shadow: 0 1px #0062ff;
}

/* .Home_link__3O3aj.lastLink {
  margin-right: auto;
} */

.Home_link__3O3ajgithub {
  margin: 0 16px 0 0;
  background-color: transparent;
  color: #78a9ff;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  fill: #78a9ff;
}

.Home_link__3O3ajgithub:hover {
  color: #a6c8ff;
  background-color: #353535;
  fill: #a6c8ff;
}

.Home_link__3O3ajgithubicon {
  margin-left: 8px;
}

.gridWrapper {
  margin-top: 112px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-items: center;
}

.gridItem {
  width: 100%;
  max-width: 336px;
  margin-bottom: 20px;

  /* margin-right: 1rem;
  margin-left: auto; */
}

.tile {
  background: #282828;
  border: none;
  padding: 0;
  transition: background-color 0.15s ease;
  height: 100%;
  min-width: 8rem;
  min-height: 4rem;
  position: relative;
  outline: 2px solid transparent;
  outline-offset: -2px;
}

.tile:hover {
  background: #3d3d3d;
}

.tile_link {
  cursor: pointer;
  text-decoration: none;
  height: 100%;
  min-height: 18.75rem;
  position: relative;
  z-index: 100;
  color: #0062ff;
}

.tile_image {
  height: 9rem;
  margin: 0rem;
  position: relative;
}

.tile_type {
  color: #bebebe;
  background: none !important;
  font-size: 0.875rem;
  margin: 0;
  font-weight: 300;
  line-height: 1rem;
  padding: 1rem;
  position: relative;
  padding-bottom: 0;
}

.tile_body {
  padding: 1rem 1rem 2rem 1rem;
}

.tile_body_title {
  color: #f3f3f3;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
}

.tile_bottom {
  min-height: 1.5rem;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  display: flex;
}

.tile_bottom_svg {
  margin-left: auto;
  fill: #408bfc;
}

.guide-section {
  margin-bottom: 64px;
}

.titlebar_mainlinks {
  /* margin-right: auto; */
  overflow: scroll;
  white-space: nowrap;
  max-width: 100%;
  height: 60px;
  line-height: 60px;
  /* display: flex;
  align-items: center; */
  /* padding: 60px 0; */
  /* tex */
}

@media (min-width: 900px) {
  .gridWrapper {
    grid-template-columns: repeat(2, 1fr);
    max-width: 688px;
  }

  .Home_link__3O3ajgithub {
    display: flex;
  }

  .Home_titleBar__3XDTs {
    display: flex;
    flex-direction: row;
    height: 70px;
  }

  .Home_title__1dfd5 {
    margin-left: 32px;
    align-items: center;
  }

  .titlebar_mainlinks {
    /* line-height: unset; */
    overflow: hidden;
    line-height: 24px;
    height: 64px;
    margin-right: auto;
    width: auto;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .gridWrapper {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1040px;
  }
}
header{
  /* box-shadow: 2px 2px 14px 0px rgb(0 0 0 / 75%); */
}
.mainPanel header{
  box-shadow: none!important;

}
.cattable th{
  width:33.3%;
  background-color: grey;
  padding: 5px;
  color: white;
}

.centerIt {
  
  display: flex;
  justify-content: center;
  align-items: center;
}

input{
  border-radius: 3px!important;
}
