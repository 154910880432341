* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e2cf81;
}
.container .card {
  height: 500px;
  width: 800px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-radius:10px ;
}
.container .card .form {
  width: 100%;
  height: 100%;
  display: flex;
}
.container .card .left-side {
  width: 50%;
  background-color: #000;
  height: 100% !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}
.left-side img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.container .card .right-side {
  width: 50%;
  background-color: #efecf2;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
}
.main {
  display: none;
}
.active {
  display: block;
}
.register p {
  display: flex;
  justify-content: end;
  font-size: 13px;
  font-weight: 600;
  color: #2a2739;
}
.register p a {
  text-decoration: none;
  color: blue;
  margin-left: 3px;
}
.hello {
  margin-top: 40px;
  text-align: center;
  color: #2a2739;
}
.hello h4 {
  margin-top: 5px;
}
.input_text {
  position: relative;
  margin-top: 20px;
}
input[type="text"] {
  height: 45px;
  width: 100%;
  border: none;
  outline: 0;
  padding: 0 10px;
  font-size: 13px;
  border-radius: 10px;
  border: 1px solid #fff;
}
input[type="password"] {
  height: 45px;
  width: 100%;
  border: none;
  outline: 0;
  padding: 0 10px;
  font-size: 13px;
  border-radius: 10px;
  padding-right: 33px;
  border: 1px solid #fff;
}
.input_text p {
  margin-top: 4px;
  font-size: 13px;
  margin-left: 10px;
  color: red;
}
.danger {
  display: none;
}
.input_text p i {
  margin-right: 2px;
}
.fa-eye-slash {
  position: absolute;
  top: 15px;
  right: 8px;
  cursor: pointer;
  color: #727286;
}
.fa-eye {
  position: absolute;
  top: 15px;
  right: 8px;
  cursor: pointer;
  color: #727286;
}
.recovery {
  margin-top: 15px;
  font-size: 13px;
  text-align: end;
}
.btn {
  margin-top: 15px;
  display: block!important;
}
.btn button {
  height: 45px;
  width: 100%;
  border: none;
  outline: 0;
  background-color: #fe6b68;
  border-radius: 10px;
  transition: all 0.5s;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
}
.btn button:hover {
  background-color: #f1221e;
}
.right-side hr {
  margin-top: 30px;
  color: #fff;
  border: 0;
  border-top: 1px solid #fff;
}
.or {
  display: flex;
  justify-content: center;
  font-size: 13px;
}
.or p {
  z-index: 2;
  margin-top: -10px;
  background-color: #efecf2;
  padding: 0 4px;
}
.boxes {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  gap: 30px;
}
.boxes span {
  height: 45px;
  width: 60px;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.5s;
}
.boxes span img {
  width: 30px;
  cursor: pointer;
  transition: all 0.5s;
}
.boxes span:hover img {
  width: 35px;
}
.boxes span:hover {
  border: 4px solid #fff;
}
.warning {
  border: 1px solid red !important;
}
@media (max-width: 750px) {
  .container .card {
    max-width: 350px;
  }
  .container .card .right-side {
    border-radius: 10px;
    width: 100%;
  }
  .container .card .left-side {
    display: none;
  }
}
.card hr {
    margin: 0px;
    margin-top: 20px;
    border: 0;
    border-top: 1px solid;
    opacity:1
}
h4{
    font-size: calc(1rem)!important;
}
.btn{
    display: block;
}
