.App {
  text-align: center;
}
body > #root >div {
  height: 100vh;
}

#root .container > .row {
  height: calc(100% - 64px);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pro-menu-item{
  padding-left: 10px ;
  padding-right: 10px ;

}


.pro-menu-item.active .pro-inner-item{
  background-color: #07ccf3;
  border-radius:5px ;
  color: black!important;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}