.customMarker {
            position: absolute;
            cursor: pointer;
            background: #424242;
            width: 100px;
            height: 100px;
            /* -width/2 */
            margin-left: -50px;
            /* -height + arrow */
            margin-top: -110px;
            border-radius: 50%;
            padding: 0px;
        }

        .customMarker:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 40px;
            border-width: 10px 10px 0;
            border-style: solid;
            border-color: #424242 transparent;
            display: block;
            width: 0;
        }

        .customMarker img {
            width: 90px;
            height: 90px;
            margin: 5px;
            border-radius: 50%;
        }
