.assistance-head {
    min-height: calc(100% - 115px);
}
a{
    text-decoration: none!important;
}
.card {
    width: 500px;
    max-width: 100%;
    box-shadow: 2px 2px 5px 0px rgb(200 200 200 / 75%)!important;
  }
  
html,
body {
    height: 100% !important;
}
.container{
    max-width: 100%!important;
    padding-top: 64px;
    height: 100%;
}
.leftPanel{
    background-color: #efefef;
    box-shadow: 2px 2px 14px 0px #e9ecef;
}
.rightPanel{
    background-color: #f9fafb;
    box-shadow: 2px 2px 14px 0px #e9ecef;
}

html,
body {
    height: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif!important;
    padding: 0;
    margin: 0;
    overflow: auto;
}

.page-container {
    height: calc(100% - 64px);
}


/* horizontal panel*/

.panel-container {
    display: flex;
    flex-direction: row;
    border: 1px solid silver;
    overflow: hidden;
    height: 100%;
}

.panel-left {
    flex: 0 0 auto;
    /* only manually resize */
    padding: 10px;
    width: 500px;
    min-height: 200px;
    min-width: 150px;
    white-space: nowrap;
    background: #fff;
    color: black;
}

.splitter {
    flex: 0 0 auto;
    width: 18px;
    background: url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/vsizegrip.png) center center no-repeat #535353;
    min-height: 200px;
    cursor: col-resize;
}

.panel-right {
    flex: 1 1 auto;
    /* resizable */
    padding: 1px;
    width: 100%;
    min-height: 200px;
    min-width: 200px;
    background: #eee;
    position: relative;
}


/* vertical panel */

.panel-container-vertical {
    display: flex;
    flex-direction: column;
    height: 500px;
    border: 1px solid silver;
    overflow: hidden;
}

.panel-top {
    flex: 0 0 auto;
    /* only manually resize */
    padding: 10px;
    height: 150px;
    width: 100%;
    white-space: nowrap;
    background: #838383;
    color: white;
}

.splitter-horizontal {
    flex: 0 0 auto;
    height: 18px;
    background: url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/hsizegrip.png) center center no-repeat #535353;
    cursor: row-resize;
}

.panel-bottom {
    flex: 1 1 auto;
    /* resizable */
    padding: 10px;
    min-height: 200px;
    background: #eee;
}

label {
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0 10px;
}

pre {
    margin: 20px;
    padding: 10px;
    background: #eee;
    border: 1px solid silver;
    border-radius: 4px;
    overflow: auto;
}

.responsive-table {
    width: 100%;
    margin-bottom: 1.5em;
    border-spacing: 0;
}

@media (min-width: 48em) {
    .responsive-table {
        font-size: 0.9em;
    }
}

@media (min-width: 62em) {
    .responsive-table {
        font-size: 1em;
    }
}

.responsive-table thead {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

@media (min-width: 48em) {
    .responsive-table thead {
        position: relative;
        clip: auto;
        height: auto;
        width: auto;
        overflow: auto;
    }
}

.responsive-table thead th {
    background-color: #26890d;
    border: 1px solid #86bc25;
    font-weight: normal;
    text-align: center;
    color: white;
}

.responsive-table thead th:first-of-type {
    text-align: left;
}

.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
}

@media (min-width: 48em) {
    .responsive-table tr {
        display: table-row;
    }
}

.responsive-table th,
.responsive-table td {
    padding: 0.5em;
    vertical-align: middle;
}

@media (min-width: 30em) {

    .responsive-table th,
    .responsive-table td {
        padding: 0.75em 0.5em;
    }
}

@media (min-width: 48em) {

    .responsive-table th,
    .responsive-table td {
        display: table-cell;
        padding: 0.5em;
    }
}

@media (min-width: 62em) {

    .responsive-table th,
    .responsive-table td {
        padding: 0.75em 0.5em;
    }
}

@media (min-width: 75em) {

    .responsive-table th,
    .responsive-table td {
        padding: 0.75em;
    }
}

.responsive-table caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}

@media (min-width: 48em) {
    .responsive-table caption {
        font-size: 1.5em;
    }
}

.responsive-table tfoot {
    font-size: 0.8em;
    font-style: italic;
}

@media (min-width: 62em) {
    .responsive-table tfoot {
        font-size: 0.9em;
    }
}

@media (min-width: 48em) {
    .responsive-table tbody {
        display: table-row-group;
    }
}

.responsive-table tbody tr {
    margin-bottom: 1em;
}

@media (min-width: 48em) {
    .responsive-table tbody tr {
        display: table-row;
        border-width: 1px;
    }
}

.responsive-table tbody tr:last-of-type {
    margin-bottom: 0;
}

@media (min-width: 48em) {
    .responsive-table tbody tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.responsive-table tbody th[scope=row] {
    background-color: #26890d;
    color: white;
}

@media (min-width: 30em) {
    .responsive-table tbody th[scope=row] {
        border-left: 1px solid #86bc25;
        border-bottom: 1px solid #86bc25;
    }
}

@media (min-width: 48em) {
    .responsive-table tbody th[scope=row] {
        background-color: transparent;
        color: #000001;
        text-align: left;
    }
}

.responsive-table tbody td {
    text-align: right;
}

@media (min-width: 48em) {
    .responsive-table tbody td {
        border-left: 1px solid #86bc25;
        border-bottom: 1px solid #86bc25;
        text-align: center;
    }
}

@media (min-width: 48em) {
    .responsive-table tbody td:last-of-type {
        border-right: 1px solid #86bc25;
    }
}

.responsive-table tbody td[data-type=currency] {
    text-align: right;
}

.responsive-table tbody td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
}

@media (min-width: 30em) {
    .responsive-table tbody td[data-title]:before {
        font-size: 0.9em;
    }
}

@media (min-width: 48em) {
    .responsive-table tbody td[data-title]:before {
        content: none;
    }
}

#id_work_days input[type="checkbox"] {
    display: none;
}

#id_work_days span {
    display: inline-block;
    padding: 10px;
    text-transform: uppercase;
    border: 2px solid gold;
    border-radius: 3px;
    color: gold;
}

#id_work_days input[type="checkbox"]:checked+span {
    background-color: gold;
    color: black;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

.vertical-tab {
    padding: 20px;
    border-radius: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    display: table;
    width: 100%;

}

.vertical-tab .nav-tabs {
    display: table-cell;
    width: 25%;
    min-width: 25%;
    border: none;
}

.vertical-tab .nav-tabs li {
    float: none;
}

.vertical-tab .nav-tabs li a {
    color: #222;
    background: #f5f5f5;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    text-align: center;
    padding: 10px;
    margin: 0 10px 10px 0;
    border-radius: 10px;
    border: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.vertical-tab .nav-tabs li a:hover,
.vertical-tab .nav-tabs li.active a,
.vertical-tab .nav-tabs li.active a:hover {
    color: #fff;
    background: #f5f5f5;
    border: none;
}

.vertical-tab .nav-tabs li a:before {
    content: '';
    background-color: #222;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transform: translateY(50px) scale(0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s ease 0s;
}

.vertical-tab .nav-tabs li a:hover:before,
.vertical-tab .nav-tabs li.active a:before,
.vertical-tab .nav-tabs li.active a:hover:before {
    transform: translateY(0) scale(1);
}

.vertical-tab .tab-content {
    color: #222;
    background: #fff;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 23px;
    padding: 15px 15px 10px 15px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: table-cell;
}

.vertical-tab .tab-content h3 {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 4px;
}

@media only screen and (max-width: 479px) {
    .vertical-tab {
        padding: 15px;
        margin: 0;
    }

    .vertical-tab .nav-tabs {
        width: 100%;
        padding: 0;
        margin: 0 0 10px;
        display: block;
    }

    .vertical-tab .nav-tabs li a {
        margin-right: 0;
    }

    .vertical-tab .tab-content {
        font-size: 14px;
        margin-top: 0;
        display: block;
    }

    .vertical-tab .tab-content h3 {
        font-size: 18px;
    }
}

.progress {
    margin-top: 20px;
    width: 100%S;
}

#infoTable thead th {
    background: black;
    color: white;
}

#infoTable tbody td {
    padding: 4px;
    font-size: 0.8em;
}

#infoTable {}

#infoTable_wrapper {
    box-shadow: 0 10px 20px -12px rgb(0 0 0 / 42%), 0 3px 20px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    padding: 10px;
    border-radius: 10px;
    margin: 0 auto;
    width: 100%;
    clear: both;
    border-collapse: collapse;
    table-layout: fixed;
    word-wrap: break-word;
}

td {
    height: 30px;
}

th {
    text-align: left;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

#menu {
    position: absolute;
    background: #efefef;
    padding: 10px;
}

#menu label {
    margin: 0px;
}
.marker {
    display: block;
    border: 2px solid white;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    background-color: blue;
    opacity: 0.6;
}

.mapboxgl-popup {
    max-width: 400px;
}

.mapboxgl-popup-content td {
    border-bottom: 1px solid #eee;
}
input{
    border-radius: 3px;
}